<!--
 * @Description: 修改日志
 * @Author: ChenXueLin
 * @Date: 2021-10-21 13:56:21
 * @LastEditTime: 2021-10-21 14:00:33
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <!-- 表格 start -->
    <section class="table-wrapper">
      <el-table border height="500px" :data="tableData" highlight-current-row>
        <!-- <el-table-column type="selection" width="40"></el-table-column> -->
        <!-- 序号 start -->
        <el-table-column
          label="序号"
          width="50"
          fixed="left"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{
                scope.$index +
                  1 +
                  (searchForm.pageIndex - 1) * searchForm.pageSize
              }}
            </span>
          </template>
        </el-table-column>
        <!-- 序号 end -->
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="column.fieldName === 'chargeCode'">
              <el-button type="text" @click="toDetail(row)">
                {{ row[column.fieldName] }}
              </el-button>
            </span>
            <span v-else>{{ row[column.fieldName] }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column> -->
      </el-table>
    </section>
    <!-- 表格 end -->

    <!-- 分页 start -->
    <section class="pagination-wrapper fixed-section">
      <el-pagination
        :page-size.sync="searchForm.pageSize"
        :current-page.sync="searchForm.pageIndex"
        :page-sizes="pageSizes"
        :layout="layout"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </section>
    <!-- 分页 end -->
  </div>
</template>
<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
export default {
  name: "workOrderList",
  data() {
    return {
      searchForm: {
        orderNo: "",
        typeId: "",
        secondType: "",
        pageIndex: 1,
        pageSize: 20
      },
      typeList: [
        {
          id: 1,
          label: "任务单"
        },
        {
          id: 2,
          label: "服务单"
        },
        {
          id: 3,
          label: "协同单"
        }
      ],
      total: 0,
      columnData: [
        {
          fieldName: "chargeCode",
          display: true,
          fieldLabel: "修改内容",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "invoiceName",
          display: true,
          fieldLabel: "修改前",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "修改后",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "invoiceName",
          display: true,
          fieldLabel: "修改人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "修改时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [
        {
          chargeCode: "11111"
        },
        {
          chargeCode: "155551"
        }
      ] // 表格数据
    };
  },
  mixins: [base, listPage, listPageReszie],

  computed: {},

  mounted() {},

  methods: {}
};
</script>
<style lang="scss" scoped>
.table-wrapper {
  margin-top: 20px;
}
</style>
